@import url("https://fonts.googleapis.com/css?family=Material+Icons|Lato:300,400,700,900&amp;display=swap");
@import "~@engie-group/fluid-design-tokens/lib/css/tokens.css";
@import "~@engie-group/fluid-design-system/lib/base.css";

@font-face {
	font-family: "Lato";
	src: local("Lato"),
		url("./common/assets/fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Lato-Bold";
	src: local("Lato-Bold"),
		url("./common/assets/fonts/Lato-Bold.ttf") format("truetype");
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Lato", "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-width: 1888px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.rs-picker-menu {
	z-index: 1200 !important;
}

.flashy {
	background-color: rgba(0, 255, 0, 1);
}
